@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

.slider {
	min-width: 600px;
	min-height: 600px;

	overflow: hidden;

	top: 0;
	width: 100%;
	height: 100%;

	z-index: 0;

	pointer-events: none;
}

.slide {
	width: 100%;
	height: 100%;
	top: 0;

	position: absolute;

	will-change: opacity, transform;
}

.type-video,
.type-image {
	background-color: #000;
}

.image {
	width: 100%;
	height: 100%;

	background-size: cover;
	background-position: center;

	background-color: #000;
}

.video {
	width: 100%;
	height: 100%;

	object-fit: cover;
}
