@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

.type-video,
.type-image {
	background-color: #000;
}

.image {
	width: 100vw;
	height: 100vh;

	background-size: cover;
	background-position: center;

	background-color: #000;

	&.image_1_5 {
		background-position: 0 center;
	}
	&.image_2_5 {
		background-position: 25% center;
	}
	&.image_3_5 {
		background-position: 50% center;
	}
	&.image_4_5 {
		background-position: 75% center;
	}
	&.image_5_5 {
		background-position: 100% center;
	}

	&.image_1_4 {
		background-position: 12.5% center;
	}
	&.image_2_4 {
		background-position: 37.5% center;
	}
	&.image_3_4 {
		background-position: 62.5% center;
	}
	&.image_4_4 {
		background-position: 87.5% center;
	}

	&.image_1_3 {
		background-position: 25% center;
	}
	&.image_2_3 {
		background-position: 50% center;
	}
	&.image_3_3 {
		background-position: 75% center;
	}

	&.image_1_2 {
		background-position: 37.5% center;
	}
	&.image_2_2 {
		background-position: 62.5% center;
	}
}

.video {
	width: 100%;
	height: 100%;

	object-fit: cover;

	&.video_1_5 {
		object-position: 0 center;
	}
	&.video_2_5 {
		object-position: 25% center;
	}
	&.video_3_5 {
		object-position: 50% center;
	}
	&.video_4_5 {
		object-position: 75% center;
	}
	&.video_5_5 {
		object-position: 100% center;
	}

	&.video_1_4 {
		object-position: 12.5% center;
	}
	&.video_2_4 {
		object-position: 37.5% center;
	}
	&.video_3_4 {
		object-position: 62.5% center;
	}
	&.video_4_4 {
		object-position: 87.5% center;
	}

	&.video_1_3 {
		object-position: 25% center;
	}
	&.video_2_3 {
		object-position: 50% center;
	}
	&.video_3_3 {
		object-position: 75% center;
	}

	&.video_1_2 {
		object-position: 37.5% center;
	}
	&.video_2_2 {
		object-position: 62.5% center;
	}
}
