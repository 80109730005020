@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

.slider {
	min-width: 600px;
	min-height: 600px;

	overflow: hidden;

	top: 0;
	width: 100%;
	height: 100%;

	z-index: 0;

	pointer-events: none;
}

.slide {
	width: 100%;
	height: 100%;
	top: 0;

	position: absolute;
}


@keyframes inAnimation {
	0% {z-index:-1;opacity:0.1; }
	100% {z-index:1;opacity:1; }
}
  
@keyframes outAnimation {
	0% {z-index:-1;opacity:1; }
	100% {z-index:1;opacity:0.1; }
}



