@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

.pageWrapper {
	font-family: $font-family-gotham;
	text-align: center;
	background-color: black;
	position: fixed;
	left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

